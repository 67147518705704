import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Auth0Service } from '../auth/services/auth0.service';

export const SKIP_ERRORS = new HttpContextToken<string | string[]>(() => '');
export const SKIP_LOG = new HttpContextToken<string | string[]>(() => '');

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private authService: Auth0Service,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      headers: request.headers.set('Cache-control', 'no-cache'),
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    request = request.clone({ headers: request.headers.set('ClientKey', '') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          this.authService.logout();
        }
        if(error && error.status === 404){
          // this.redirectWhenError()
        }
        let skipErrors = request.context.get(SKIP_ERRORS);
        const errorObject =
          typeof error.error === 'string'
            ? JSON.parse(error.error)
            : error.error;
        const isSkipError =
          skipErrors.includes(error.status.toString()) ||
          skipErrors.includes(errorObject?.errorType);

        // Cast Error object to json to pass in the redux action
        error = JSON.parse(JSON.stringify(error));

        return throwError(error);
      })
    );
  }

}
