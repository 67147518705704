import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuard, AuthHttpInterceptor, AuthModule as Auth0Module, AuthService } from '@auth0/auth0-angular';
import { ClientKeyInterceptor } from '../interceptor/client-key.interceptor';
import { HttpConfigInterceptor } from '../interceptor/httpconfig.interceptor';
import { auth0Config } from './configs/auth0.config';


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        Auth0Module.forRoot({
            ...auth0Config.config
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ClientKeyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        AuthGuard,
        AuthService
    ],
    exports: [

    ]
})
export class AuthModule { }
