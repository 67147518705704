import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Auth0Service } from './core/auth/services/auth0.service';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ptg-login';
  isLogin = false;

  private readonly _destroying$ = new Subject<void>();
  versionControl: FormControl = new FormControl('');

  versions: any[] = [{label: 'MetaData Version', value: 1}, {label: 'Entity Version', value: 2}];

  constructor(
    public authService: Auth0Service,
    public router: Router,
    public route: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.checkAuthenticationState();
  }

  checkAuthenticationState() {
    this.authService.initializeAuth();
    this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        localStorage.clear();
        location.reload();
        return;
      } else {
        this.authService.idTokenClaim$
          .pipe(
            filter(idTokenClaim => !!idTokenClaim)
          )
          .subscribe(() => {
            if (!this.authService.isSysAdmin) {
              this.cookieService.set('version', this.authService.appVersion === 2 ? '2' : '1');
              window.location.reload();
              return;
            }
            this.isLogin = isAuthenticated;
          });
        
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  selectVersion(event: MatSelectChange) {
    this.cookieService.set('version', event.value);
    window.location.reload();
  }
}
