import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ClientKeyInterceptor implements HttpInterceptor {
  activeFund: any;

  constructor() {
    // this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.activeFund?.key) {
      request = request.clone({
        headers: request.headers
        .set('x-ptg-client-id', this.activeFund?.id)
        .set('x-ptg-client-key', this.activeFund?.key)
      });
    }

    return next.handle(request);
  }
}
